import { ResourceHeaders } from '..'
import { BusinessHeader, DateHeader, IdHeader, NegotiationStatusHeader, PriceHeader, VehicleHeader } from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'
import { CellType } from '@/components/dataTables/cell'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 80,
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'auto' },
  { ...DateHeader, text: 'Fecha creación', value: 'created' },
  { ...BusinessHeader, text: 'Negocio', value: 'pipeline' },
  { ...NegotiationStatusHeader, text: 'Estado negociación', value: '@' },
  {
    align: 'center',
    width: 100,
    filterable: true,
    type: CellType.linked,
    text: 'Ejecutivo',
    value: 'executive',
    options: {
      action: {
        color: 'green',
        icon: 'mdi-whatsapp',
        only: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...PriceHeader, text: 'Precio autorizado', value: 'negotiationResponse' },
  { ...PriceHeader, text: 'Precio apelado', value: 'negotiationResponseAppealed' },
]

export default function (): ResourceHeaders {
  return headers
}
