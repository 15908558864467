
  import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator'
  import { RotationIndex, VersionYear } from '@/entities/vehicle'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { RULES } from '@/components/forms'
  import { Person } from '@/entities/persons'
  import GFiles from '@/components/core/files/GFiles.vue'
  import VersionCellManager from '@/components/dataTables/cell/VersionManager/VersionCellManager.vue'

@Component({
  components: { VersionCellManager, GFiles, GCostField },
})
  export default class SiiRow extends FilesProcess {
  @Prop({ type: Object, default: null }) readonly person!: Person;
  @Prop({ type: VersionYear, default: null }) data!: VersionYear;
  @Prop() isSelected!: boolean;
  @Prop({ type: Boolean, default: false }) readonly displaySelector!: boolean;
  @Prop({ type: Array, default: () => [] }) readonly rotationIndex!: RotationIndex[];
  @Prop({ type: Number, default: null }) readonly idProcess!: number;
  @Prop({ type: Number, default: null }) readonly idProcessMileage!: number;
  @Prop({ type: String, default: null }) readonly aliasBrand!: string;
  @Prop({ type: String, default: null }) readonly aliasModel!: string;
  @Prop({ type: String, default: null }) readonly linkModel!: string;
  @Prop({ type: Object, default: () => null }) photoMileageProperties!: Record<string, any>;
  @Prop({ type: Array, default: () => [] }) readonly backupPhoto!: Array<Record<string, any>>;
  @Ref('form') readonly form: any;

  loading = false
  photo = null
  pdf = null
  rule = RULES.isRequired
  linkRule = RULES.isUrl

  isLinkValid (link) {
    return this.linkRule.every(rule => rule(link) === true)
  }

  @Emit('toggle-select')
  toggleSelection () {
    return this.data && this.data.id ? this.data.id : null
  }

  get isPhoto () {
    const { photo } = this

    return photo?.length > 0
  }

  get isPdf () {
    const { pdf } = this

    return pdf?.length > 0
  }

  @Watch('data', { immediate: true })
  async onDataChange (val: any) {
    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: val.id } },
          { parameter: { process: { id: { _eq: this.idProcess } } } },
        ],
      },
      force: true,
    })

    const photos = files?.filter((item: any) => item.parameter.name === 'version_model_photo')
    const pdf = files?.filter((item: any) => item.parameter.name === 'technical_sheet_model')

    this.photo = photos?.[0]?.url
    this.pdf = pdf?.[0]?.url
  }
  }
